.wrapper {
  display: flex;
}
.navbar > .w-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 940px;
}

.navbar > .container {
  max-width: 1248px;
  padding-right: 24px;
  padding-left: 24px;
  display: block;
}

.navbar > .brand {
  position: relative;
  display: block;
  width: 126px;
  height: 100%;
  margin-top: 32px;
  padding-bottom: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.rocket-img {
  position: relative;
  margin: 0 auto;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1;
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.card-form {
  opacity: 0.9;
}

.latest-profiles-img {
  width: 40px;
  height: 40px;
}

.form-control::placeholder {
  color: #bbb !important;
}

.login,
.register,
.create-profile {
  margin-top: 80px;
  margin-bottom: 80px;
}

.create-application {
  margin-top: 40px;
  margin-bottom: 80px;
}
.avatar {
  margin-left: 10px;
}

a:hover {
  color: #eee;
  text-decoration: none;
  /* background-color: #00b3db;
  border-color: #285e8e;*/
}
a.button.w-button {
  margin-right: 10px;
}
#sidebar ul li.active > a,
a[aria-expanded="true"] {
  color: #fff;
  background: transparent;
}

a.create-btn {
  margin: 15px 0 0 0;
}

.navbar > .brand {
  margin-left: 25px;
}

button {
  outline: none !important;
}
.row > div > img {
  width: 100%;
  display: block;
}

.iframe_container {
  position: relative;
  padding-bottom: 62.5%;
  height: 0;
}
.iframe_style {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

nt-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: local("Montserrat Regular"), local("Montserrat-Regular"),
    url(https://fonts.gstatic.com/s/montserrat/v12/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2)
      format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: local("Montserrat Regular"), local("Montserrat-Regular"),
    url(https://fonts.gstatic.com/s/montserrat/v12/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2)
      format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: local("Montserrat Regular"), local("Montserrat-Regular"),
    url(https://fonts.gstatic.com/s/montserrat/v12/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2)
      format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: local("Montserrat Regular"), local("Montserrat-Regular"),
    url(https://fonts.gstatic.com/s/montserrat/v12/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2)
      format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: local("Montserrat Regular"), local("Montserrat-Regular"),
    url(https://fonts.gstatic.com/s/montserrat/v12/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: local("Montserrat Bold"), local("Montserrat-Bold"),
    url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_dJE3gTD_vx3rCubqg.woff2)
      format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: local("Montserrat Bold"), local("Montserrat-Bold"),
    url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_dJE3g3D_vx3rCubqg.woff2)
      format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: local("Montserrat Bold"), local("Montserrat-Bold"),
    url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_dJE3gbD_vx3rCubqg.woff2)
      format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: local("Montserrat Bold"), local("Montserrat-Bold"),
    url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_dJE3gfD_vx3rCubqg.woff2)
      format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: local("Montserrat Bold"), local("Montserrat-Bold"),
    url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_dJE3gnD_vx3rCs.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* Non minified css changes */
.iterate-sidebar {
  color: white;
  position: fixed;
  padding: 20px;
  width: 165px;
  height: 100vh;
  left: 0;
  top: 58px;
  z-index: 900;
  display: flex;
  flex-direction: column;
  background: rgb(246, 117, 0);
}

.iterate-sidebar a {
  color: white !important;
  padding: 8px;
  font-size: 16px;
  font-weight: 300 !important;
  font-family: Roboto;
  white-space: nowrap;
}

.interplay-content {
  margin-left: 165px !important;
  padding: 16px;
  min-width: 300px;
}

.iterate-sidebar a:hover {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 8px;
}

.iterate-sidebar > a + a {
  margin-top: 10px;
}

.iterate-sidebar .utf8-icon {
  margin-right: 5px;
  font-size: 18px;
}

.iterate-sidebar span.utf8-icon.cloud {
  margin-right: 10px;
}

ul.usecase-grid.card-grid.orange.microservice_listing.reload_data {
  padding: 16px;
}

nav.navbar {
  z-index: 910;
}

.tutorials-main {
  width: calc(100%) !important;
}

.tutorials-grid {
  display: grid;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0 !important;
}

.tutorials-grid .item.card p {
  color: #1298ec !important;
  font-size: 16px;
  padding: 4px 0 8px 0;
}

.resp-container {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
}

.resp-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

#footer {
  margin-left: 165px;
}

@media screen and (max-width: 950px) {
  .iterate-sidebar {
    display: none;
  }

  .tutorials-grid {
    grid-template-columns: 1fr;
  }

  .tutorials-main {
    margin-left: 0 !important;
  }

  .tutorials-main.container {
    width: 100% !important;
  }

  .interplay-content {
    margin-left: 0 !important;
  }

  #footer {
    margin-left: 0;
  }
}

.domain-input-group {
  display: flex;
  align-items: baseline;
  align-content: center;
  justify-content: center;
  padding: 12px 0;
}

.domain-input-group div + div {
  margin-left: 4px;
}

.domain-input-group .http-parts {
  font-size: 16px;
  position: relative;
}

.domain-input-group input#name {
  font-size: 18px;
  width: 150px;
  padding: 0 5px;
  background-color: #eaf7ff;
}

.domain-modal .hint {
  color: #aaa;
  font-size: 12px;
  padding: 8px;
}

.domain-modal .select-region-label {
  font-size: 16px;
}

.domain-modal .instance_size {
  max-width: 200px;
}
li {
  list-style: none !important;
}

.data-center .card-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 0.5rem;
}

.row .data-center {
  margin-bottom: 20px;
  background-color: #eaecf4;
}
#next-button,
#prev-button {
  margin: 10px;
}

a.select-btn,
a.learnmore-btn {
  position: absolute;
  left: 20px;
  bottom: 20px;
}
a.learnmore-btn {
  left: 95px;
}

.status {
  display: block;
  position: relative;
  padding: 0;
  margin: 8px auto;
  height: 5px;
  width: 100%;
  max-height: 0;
  font-size: 1px;
  line-height: 0;
  clear: both;
  border: none;
  border-top: 5px solid green;
  border-bottom: 1px solid #ffffff;
}
.status_ONLINE {
  border-top: 5px solid green;
}

.status_OFFLINE {
  border-top: 5px solid gray;
}
.status_FAULTED {
  border-top: 5px solid red;
}
.role {
  border: 1px solid green;
  border-radius: 5px;
  padding: 2px;
}
.right {
  position: absolute;
  right: 10px;
}
#node_summary .card {
  min-height: 180px;
}
.card:hover div {
  transition: 0.3s ease-in-out;
  transform: translate3d(0, 0, 0);
  opacity: 0.85;
  transform: scale(0.95);
  cursor: pointer;
}
.progress {
  height: 0.5rem;
}
.progress-bar {
  background-color: green;
}
p {
  font-size: 0.9em;
}

#main_content {
  padding-top: 20px;
}

.border-left-card {
  border-left: 0.25rem solid #485361 !important;
}
.essential_title {
  text-color: #485361 !important;
}

.app_type_button {
  position: absolute;
  bottom: 20px;
  left: 87px;
}

.app_card {
  min-height: 300px;
}
.k-animation-container {
  z-index: 10003;
}

.navbar-light {
  background-color: white !important;
}


body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}


.bubble_element {
    text-align: center;
    box-sizing: border-box;
    z-index: 0;
    height: 60px;
    width: 60px;
    left: 0px;
    top: 0px;
    background-color: rgb(109, 109, 209);
    border-radius: 60px;
}
.center {
  margin: auto;
  
  padding: 10px;
}
.imgCenter{
display: block;
margin-left: auto;
margin-right: auto;
width: 40%;
}

.brand, .navbar > .brand { 
  margin-top: 0;
}


.visible.transition {
  margin-top: auto !important;
  display: inline-block !important;
  position: relative;
  top: 20%;
}

ol.ui.list li:before, .ui.ordered.list .list > .item:before, .ui.ordered.list > .item:before {

  left:10px;
}