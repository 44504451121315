body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.ui.vertical.menu {
  background: rgba(0,0,0,0);
  width: 100%
}

.ui.vertical.menu .item,.ui.vertical.menu .menu .item {
  color: #ffffff
}
.ui.vertical.menu .item .menu a.item:hover, .ui.vertical.menu .item .menu .link.item:hover {
  color: #dddddd
}

.ui.vertical.menu .item .menu .active.item {
  background-color: rgb(235, 236, 240);
}

.bubble_element {
  
  background-color: rgb(100, 53, 201);
}


ol.ui.list li, .ui.ordered.list .list > .item, .ui.ordered.list > .item {
  font-size: 1.1em;
  font-weight: 600;
  margin-left: 15px;
    padding-left: 5px;
}
ol.ui.list, .ui.ordered.list, .ui.ordered.list .list, ol.ui.list ol {
  margin-left: 2.25rem;
}