/****************************************************************/
/*					Card Style Start Here 						*/
/****************************************************************/
.card .card-drop {
  position: absolute;
  top: 0;
  right: 15px;
}
.card .card-drop a .dic {
  color: #898989;
}
.card .card-drop .dropdown-menu {
  left: auto;
  right: 0;
  top: 25px;
}
.card .card-drop .dropdown-menu .btn {
  color: #898989;
  padding: 12px 16px;
  min-width: 5px;
  text-transform: capitalize;
  text-decoration: none;
  display: block;
  width: 100%;
  text-align: left;
}
.card .card-drop .dropdown-menu a:hover,
.card .card-drop .dropdown-menu .btn:hover {
  background: rgba(0, 0, 0, 0.03);
  color: #333;
}
.blue .card a.orange-color:hover,
.blue .card a.blue-color:hover,
.orange .card a.orange-color:hover,
.orange .card a.blue-color:hover {
  color: #333;
}
.card a.orange-color:hover {
  color: #333;
}
.card a.blue-color:hover {
  color: #333;
}

.card.dropdown-menu .dropdown-title {
  margin: 0;
}
.card-media-text {
  line-height: 18px;
  color: rgba(0, 0, 0, 0.87);
  opacity: 1;
}
.card-counters.row {
  width: auto;
  display: block;
}
.card .card-counters.row {
  margin-right: 0;
  margin-left: 0;
  display: none;
}
.card-margin {
  margin-bottom: 20px;
}
.card .fileinput-action {
  padding: 15px 20px;
}
/* ============== Landscape Card Style Start  ============== */
.card-blankstate {
  border-radius: 3px;
  display: inline-table;
  width: 100%;
  max-width: 780px; /* height: 350px; */
  background: none;
  padding: 0;
  vertical-align: middle;
}
.card-blankstate a {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  color: #999999;
  border: 2px dashed #cccccc;
}
.card-blankstate .icon-add {
  width: 28px;
  height: 28px;
  background: #999999;
  border-radius: 3px;
  display: inline-block;
  position: relative;
  margin-bottom: 9px;
}
.card-blankstate .icon-add:after,
.card-blankstate .icon-add:before {
  position: absolute;
  top: 50%;
  left: 50%;
  content: "";
  background: #f0f4f5;
}
.card-blankstate .icon-add:after {
  width: 16px;
  height: 2px;
  margin-top: -1px;
  margin-left: -8px;
}
.card-blankstate .icon-add:before {
  width: 2px;
  height: 16px;
  margin-top: -8px;
  margin-left: -1px;
}
.card-blankstate span {
  display: block;
}
.card-blankstate.full-width {
  max-width: 100%;
  height: 235px;
}
.card-blankstate a:hover {
  color: #00adee;
  border-color: #00adee;
}
.card-blankstate a:hover .icon-add {
  background: #00adee;
}
.card-blankstate.orange a:hover {
  color: #e36d0e;
  border-color: #e36d0e;
}
.card-blankstate.orange a:hover .icon-add {
  background: #e36d0e;
}
/* ============== Landscape Card Style Ends  ============== */

/* ============== Landscape Card Style Ends  ============== */
.landscape-card .card-media {
  height: 165px;
  overflow: hidden;
  position: relative;
}
.landscape-card .card-media a {
  border: 1px dashed #d0cccc;
  padding: 3px;
  margin: 5px 5px 0;
  display: block;
}
.landscape-card .card-media img {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  -webkit-transform-origin: left top;
  -moz-transform-origin: left top;
  -ms-transform-origin: left top;
  -o-transform-origin: left top;
  transition: all ease-in-out 0.3s;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition-delay: 1.5s;
  -webkit-transition-delay: 1.5s;
  -moz-transition-delay: 1.5s;
  -ms-transition-delay: 1.5s;
  -o-transition-delay: 1.5s;
}
.landscape-card .card-media img.transition {
  -webkit-transform: scale(2.2);
  -moz-transform: scale(2.2);
  -o-transform: scale(2.2);
  transform: scale(2.2);
}
.landscape-card .card-media:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 3px;
  background: #fff;
}
.landscape-card .card-body .card-heading {
  padding: 0;
  margin: 0;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 290px;
}
.landscape-card .card-body .card-heading a {
  color: #333;
}
.landscape-card .card-body .btn-landscape-edit {
  margin-top: 4px;
  display: inline-block;
  opacity: 0;
  transition: all ease-in-out 0.2s;
  -moz-transition: all ease-in-out 0.2s;
  -ms-transition: all ease-in-out 0.2s;
  -webkit-transition: all ease-in-out 0.2s;
  -o-transition: all ease-in-out 0.2s;
}
.landscape-card:hover .card-body .btn-landscape-edit {
  opacity: 1;
}

.landscape-card .case-posted .media-right {
  padding-left: 4px;
}
.landscape-card .case-posted .media-right .btn {
  min-width: 10px;
  padding-left: 9px;
  padding-right: 9px;
}
.landscape-card .card-footer.card-counters {
  display: block;
}

.landscape-card .landscape-tags {
  margin-top: 20px;
  position: relative;
}
.landscape-card .landscape-tags h4 {
  color: rgba(0, 0, 0, 0.4);
  font-size: 11px;
  text-transform: uppercase;
}
.landscape-card .landscape-tags > a {
  margin: 0 2px 5px 0;
}
.landscape-card .landscape-tags .more-dropdown {
  display: inline-block;
  vertical-align: top;
  position: initial;
}
.landscape-card .landscape-tags .dropdown-toggle {
  display: inline-block;
  background: #fff;
  border: 1px solid #e36d0e;
  padding: 4px 8px;
  border-radius: 3px;
  margin: 0;
  color: #e36d0e;
  line-height: 1.5;
  text-transform: uppercase;
  min-width: inherit;
  vertical-align: middle;
  font-size: 12px;
  position: relative;
}
.landscape-card .landscape-tags .open .dropdown-toggle {
  z-index: 10000;
}
.landscape-card .landscape-tags .open .dropdown-toggle:after,
.landscape-card .landscape-tags .open .dropdown-toggle:before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -6px;
}
.landscape-card .landscape-tags .open .dropdown-toggle:after {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid rgba(0, 0, 0, 0.1);
  z-index: 10;
  margin-left: -6px;
}
.landscape-card .landscape-tags .open .dropdown-toggle:before {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #fff;
  z-index: 11;
  margin-left: -5px;
}
.landscape-card .landscape-tags .more-dropdown .profile-tags {
  margin: 0;
  padding: 8px 15px 2px;
}

/* Media Query start here */
@media screen and (max-width: 1200px) {
  .landscape-card .card-body .card-heading {
    max-width: 224px;
  }
}
@media screen and (max-width: 991px) {
  .landscape-card .card-body .card-heading {
    max-width: 276px;
  }
}
@media screen and (max-width: 767px) {
  .landscape-card .card-body .card-heading {
    max-width: 100%;
    white-space: normal;
    overflow: visible;
    text-overflow: initial;
  }
}
/* ============== Landscape Card Style Ends  ============== */
/* ============== Card count Card Style Start  ============== */
.card.card-count {
  border-bottom: 2px solid #333; /* margin-bottom:30px; */
}
.card.card-count .count-wrap {
  position: relative;
  padding: 9px 10px 1px;
  float: left;
  width: 16.667%;
  border-right: 1px solid #ededed;
}
.card.card-count .count-wrap.no-border {
  border: none;
}
.card.card-count .count-wrap p {
  margin-bottom: 0;
  color: rgba(0, 0, 0, 0.65);
}
.card.card-count .count-wrap strong {
  font-size: 32px;
  font-weight: 700;
}
.card.card-count .count-wrap span {
  line-height: 45px;
  font-size: 16px;
}
.card.card-count.blue {
  border-color: #00adee;
}
.card.card-count.orange {
  border-color: #e36d0e;
}
@media screen and (max-width: 767px) {
  .card.card-count .count-wrap {
    position: relative;
    padding: 9px 0 9px;
    border-right: none;
    border-bottom: 1px solid #ededed;
  }
  .card.card-count .count-wrap {
    width: 100%;
  }
}
/* ============== Card count Style Ends  ============== */

/* ============== User Card Style Ends  ============== */
.card.user-card .heading {
  margin: 10px 0 3px;
  font-weight: 400;
  color: #333;
}
.card.user-card .card-body p,
.card.user-card .card-body p a,
.card.user-card .heading {
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
  line-height: normal;
}
.card.user-card .card-body p a {
  display: inline-block;
  width: auto;
  max-width: 100%;
}
.card.user-card p {
  color: #999;
  margin: 0;
}
.card.user-card .card-body {
  min-height: 170px;
}

.orange .card.user-card p a {
  color: #e36d0e;
}
.blue .card.user-card p a {
  color: #09b0ef;
}

.card.user-card .btn {
  padding: 4px 9px;
  margin: 0 2px;
}
.card.user-card .btn:first-child {
  margin-left: 0;
}
.card.user-card .btn:last-child {
  margin-right: 0;
}
.card.user-card .at-share-tbx-element,
.card.user-card .addthis_sharing_toolbox {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.card.user-card .btn.btn-share {
  position: relative;
}
.card.user-card .btn .at-share-btn {
  display: none;
}
.card.user-card .btn .at-svc-compact {
  display: block;
  opacity: 0;
  position: absolute;
  margin: 0;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  min-width: 100%;
}

.card.user-card .top-action {
  position: absolute;
  top: 0;
  right: 16px;
  text-align: right;
}
.card.user-card .top-action .top-action > a {
  width: 20px;
  height: 20px;
  line-height: 20px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 20px;
}
.card.user-card .top-action .delete-list {
  opacity: 0;
  transition: all ease-in-out 0.3s;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
}
.card.user-card:hover .top-action .delete-list {
  opacity: 1;
}

.card-counters > div {
  padding: 10px 10px;
  text-align: center;
  border-right: 1px solid #eee;
}
.card-counters > div:last-child {
  border: none;
}
.card-counters span {
  color: rgba(0, 0, 0, 0.87);
  font-size: 13px;
  display: block;
  margin: 0 0 3px 0;
  font-weight: 400;
}
.card .card-counters span {
  font-weight: 400;
  line-height: 1;
  margin-bottom: 4px;
}
.card-counters strong {
  font-size: 25px;
  color: rgba(0, 0, 0, 0.4);
  font-weight: 500;
  line-height: 1;
}

.right-panel .block.card-counters,
.card-counters {
  padding: 0;
  border-top: 1px solid #eee;
}
.card-counters > div {
  padding: 10px 10px;
  text-align: center;
  border-right: 1px solid #eee;
}
.card-counters > div:last-child {
  border: none;
}
.card-counters span {
  color: rgba(0, 0, 0, 0.87);
  font-size: 13px;
  display: block;
  margin: 0 0 3px 0;
  font-weight: 400;
}
.card .card-counters span {
  font-weight: 400;
  line-height: 1;
  margin-bottom: 4px;
}
.card-counters strong {
  font-size: 25px;
  color: rgba(0, 0, 0, 0.4);
  font-weight: 500;
  line-height: 1;
}

/* ============== User Card Style Ends  ============== */
.card.application-card {
  padding: 0;
}
.card.application-card .card-heading {
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 20px;
  font-weight: 400;
  color: #e36d0e;
  line-height: 1.4;
}
.card.application-card .card-footer {
  border-top: 1px solid #eee;
}
.card.application-card .card-footer .btn {
  margin-left: 10px;
}
.card.application-card .block {
  margin-top: 10px;
  margin-bottom: 6px;
}
.card.application-card .block span.section-title {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.45);
}
.card.application-card .block h3 {
  margin: 2px 0 0;
  color: rgba(0, 0, 0, 0.65);
}
.card.application-card .block h3 small {
  color: rgba(0, 0, 0, 0.65);
  font-weight: 600;
}
.card.application-card .card-counters.row {
  display: block;
}
.card.application-card .card-counters span {
  color: rgba(0, 0, 0, 0.45);
}
.card.application-card .card-counters strong {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.65);
}
.card-grid {
  opacity: 1;
}
/* Blank Card */
.card-blank {
  display: inline-table;
  width: 100%;
  max-width: 800px;
  height: 244px;
  background: none;
  padding: 0;
}
.card-blank .card-link {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  font-size: 24px;
  color: #999999;
  border: 2px dashed #cccccc;
}
.orange .card-blank:hover .card-link {
  border-color: #e36d0e;
}
.orange .card-blank:hover .dic {
  background-color: #e36d0e;
}
.orange .card-blank:hover span {
  color: #e36d0e;
}
.blue .card-blank:hover .card-link {
  border-color: #00adee;
}
.blue .card-blank:hover .dic {
  background-color: #00adee;
}
.blue .card-blank:hover span {
  color: #00adee;
}
.card-blank .dic {
  width: 30px;
  height: 30px;
  background: #999999;
  border-radius: 3px;
  display: inline-block;
  position: relative;
  color: #fff;
  line-height: 30px;
  line-height: 30px;
  font-size: 19px;
}
.card-blank span {
  display: block;
  margin-top: 16px;
  font-size: 18px;
  font-weight: 500;
  color: #666;
}
