.ui.card {
  width: 100% !important; }

.ui.segment {
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 2px 20px 0 rgba(185, 188, 210, 0.2);
  border: solid 1px #e8e8ec; }

.fixed-footer {
  margin: -1rem 0 50px 0 !important;
  padding: 1.5rem 1rem !important;
  border-radius: 4px;
  background-color: #3cbfc8;
  color: #fff; }

.one.column.row {
  text-align: center !important; }
